import React, { useState, useEffect } from 'react';
import {
  Card,
  CardBody,
  CardFooter,
  Button,
  Input,
  InputGroup,
  InputGroupAddon,
  FormGroup,
  Label,
  Breadcrumb,
  BreadcrumbItem,
  Row,
  Col,
} from 'reactstrap';
import FA from 'react-fontawesome';
import { BrowserRouter as Router, Switch, Route, Link, useParams, useRouteMatch, useHistory } from 'react-router-dom';
import { fetchWithGet, fetchWithPost, fetchWithFormData } from '../../httpHelper';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import _ from 'lodash';
import MUIDataTable from 'mui-datatables';
import PageAlertContext from '../../components/components/PageAlert/PageAlertContext';
import { Loader } from '../../components';
import { CopyToClipboard } from 'react-copy-to-clipboard';

function MyEditor({ onChange, value, placeholder }) {
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [initValue, setInitValue] = useState('');
  useEffect(() => {
    if (!initValue && value) {
      setInitValue(value);
      const contentBlock = htmlToDraft(value);
      if (contentBlock) {
        const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
        const contentEditorState = EditorState.createWithContent(contentState);
        setEditorState(contentEditorState);
      }
    }
  }, [value]);

  const handleEditorChange = (editorState) => {
    setEditorState(editorState);
    onChange(draftToHtml(convertToRaw(editorState.getCurrentContent())));
  };
  const uploadCallback = async (file) => {
    const result = await fetchWithFormData('/content/uploadImage', { location: 'blog', image: file });
    return result;
  };

  return (
    <Editor
      toolbar={{ image: { previewImage: true, uploadCallback } }}
      editorState={editorState}
      wrapperClassName="rich-editor demo-wrapper"
      editorClassName="demo-editor"
      onEditorStateChange={handleEditorChange}
      placeholder={placeholder}
    />
  );
}

export default function Blog(props) {
  let { path, url } = useRouteMatch();

  return (
    <Router>
      <Switch>
        <Route exact path={path}>
          <BlogList rootPath={url} />
        </Route>
        <Route path={`${path}/create`}>
          <BlogEdit rootPath={url} />
        </Route>
        <Route path={`${path}/:id`}>
          <BlogEdit rootPath={url} />
        </Route>
      </Switch>
    </Router>
  );
}

function BlogList({ rootPath }) {
  const history = useHistory();
  const [blogs, setBlogs] = useState([]);
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const columns = ['#', 'Title', 'Type', 'Tag', 'Sequence', 'Hided', 'External Link'];
  const options = {
    selectableRows: 'none',
    download: false,
    onRowClick: (rowData, rowMeta) => {
      window.open(`${rootPath}/${blogs[rowMeta.dataIndex].id}`, '_blank');
      // history.push(`${rootPath}/${blogs[rowMeta.dataIndex].id}`);
    },
    print: false,
    textLabels: {
      body: {
        noMatch: isLoading ? <Loader type={'spin'} /> : 'Sorry, no matching records found',
      },
    },
  };

  useEffect(() => {
    fetchApi();
  }, []);
  const fetchApi = async () => {
    setIsLoading(true);
    const result = await fetchWithGet('/content/blog');
    if (result) {
      setBlogs(result);
      setData(
        result.map((v, i) => [
          i + 1,
          v.title.en + '\n' + v.title.zh,
          _.startCase(v.type),
          _.startCase(v.tag),
          v.sequence,
          v.isHided ? 'True' : 'False',
          v.external ? 'True' : 'False',
        ])
      );
    }
    setIsLoading(false);
  };

  return (
    <div>
      <Breadcrumb>
        <BreadcrumbItem>
          <Link to={`${rootPath}`}>Blogs</Link>
        </BreadcrumbItem>
      </Breadcrumb>
      <div className="text-right mb-2">
        <Button color="primary" onClick={() => history.push(`${rootPath}/create`)}>
          Add
        </Button>
      </div>
      <MUIDataTable data={data} columns={columns} options={options} />
    </div>
  );
}

function BlogEdit({ rootPath }) {
  let { id } = useParams();
  let { url } = useRouteMatch();
  const history = useHistory();

  const [blogId, setBlogId] = useState(id);
  const [titleZh, setTitleZh] = useState('');
  const [titleEn, setTitleEn] = useState('');
  const [authorZh, setAuthorZh] = useState('');
  const [authorEn, setAuthorEn] = useState('');
  const [type, setType] = useState('all');
  const [track, setTrack] = useState('');
  const [tag, setTag] = useState('all');
  const [subTag, setSubTag] = useState('');
  const [thumbnail, setThumbnail] = useState('');
  const [thumbnailEn, setThumbnailEn] = useState('');

  const [isExternal, setIsExternal] = useState(false);
  const [externalUrl, setExternalUrl] = useState('');

  const [descZh, setDescZh] = useState('');
  const [descEn, setDescEn] = useState('');

  const [contentZh, setContentZh] = useState('');
  const [contentEn, setContentEn] = useState('');

  const [isHided, setIsHided] = useState(false);
  const [sequence, setSequence] = useState(0);

  useEffect(() => {
    fetchApi();
  }, []);
  const fetchApi = async () => {
    if (id) {
      setBlogId(id);
      const result = await fetchWithGet(`/content/blog/${id}`);
      if (result) {
        setTitleZh(result.title.zh);
        setTitleEn(result.title.en);
        setAuthorZh((result.author || {}).zh || '');
        setAuthorEn((result.author || {}).en || '');
        setType(result.type || 'all');
        setTrack(result.track || '');
        setTag(result.tag || 'all');
        setSubTag(result.subTag || '');
        setThumbnail(result.thumbnail);
        setThumbnailEn(result.thumbnailEn);
        setIsExternal(result.external);
        setExternalUrl(result.externalUrl);
        setDescZh(result.description?.zh);
        setDescEn(result.description?.en);
        setContentZh(result.content.zh);
        setContentEn(result.content.en);
        setIsHided(result.isHided);
        setSequence(result.sequence);
      }
    }
  };
  const saveBlog = async (context) => {
    if (titleEn && titleZh && authorZh && authorEn) {
      if (isExternal) {
        if (externalUrl) {
          const result = await fetchWithPost('/content/blog', {
            id: blogId,
            title: {
              zh: titleZh,
              en: titleEn,
            },
            author: {
              zh: authorZh,
              en: authorEn,
            },
            description: {
              zh: descZh,
              en: descEn,
            },
            type,
            track: type === 'case' ? track : '',
            tag,
            subTag: tag === 'casestudy' ? subTag : '',
            thumbnail,
            thumbnailEn,
            external: isExternal,
            externalUrl,
            content: {
              zh: contentZh,
              en: contentEn,
            },
            isHided,
            sequence,
          });
          if (result && result.status === 400) {
            context.setAlert(result.data.message, 'danger');
            setTimeout(() => context.closeAlert(), 2000);
          } else {
            context.setAlert('Successfully updated', 'success');
            setTimeout(() => context.closeAlert(), 2000);
            history.push(`${rootPath}`);
          }
          // if (result) {
          //   history.push(`${rootPath}`);
          // }
        } else {
          context.setAlert('Please fill in all the blank', 'danger');
          setTimeout(() => context.closeAlert(), 2000);
        }
      } else {
        if (contentEn && contentZh) {
          const result = await fetchWithPost('/content/blog', {
            id: blogId,
            title: {
              zh: titleZh,
              en: titleEn,
            },
            description: {
              zh: descZh,
              en: descEn,
            },
            author: {
              zh: authorZh,
              en: authorEn,
            },
            type,
            track: type === 'case' ? track : '',
            tag,
            subTag: tag === 'casestudy' ? subTag : '',
            thumbnail,
            thumbnailEn,
            external: isExternal,
            externalUrl,
            content: {
              zh: contentZh,
              en: contentEn,
            },
            isHided,
            sequence,
          });
          if (result && result.status === 400) {
            context.setAlert(result.data.message, 'danger');
            setTimeout(() => context.closeAlert(), 2000);
          } else {
            context.setAlert('Successfully updated', 'success');
            setTimeout(() => context.closeAlert(), 2000);
            if (!id) history.push(`${rootPath}`);
          }
          // if (result) {
          //   history.push(`${rootPath}`);
          // }
        } else {
          context.setAlert('Please fill in all the blank', 'danger');
          setTimeout(() => context.closeAlert(), 2000);
        }
      }
    } else {
      context.setAlert('Please fill in all the blank', 'danger');
      setTimeout(() => context.closeAlert(), 2000);
    }
  };

  const uploadThumbnail = async (v) => {
    if (v.target.files.length > 0) {
      const result = await fetchWithFormData('/content/uploadImage', { location: 'blog', image: v.target.files[0] });
      if (result) {
        setThumbnail(result.data.link);
      }
    }
  };

  const uploadThumbnailEn = async (v) => {
    if (v.target.files.length > 0) {
      const result = await fetchWithFormData('/content/uploadImage', { location: 'blog', image: v.target.files[0] });
      if (result) {
        setThumbnailEn(result.data.link);
      }
    }
  };

  return (
    <div>
      <div className="mb-2">
        <Breadcrumb>
          <BreadcrumbItem>
            <Link to={`${rootPath}`}>Blogs</Link>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <Link to={`${url}/${id}`}>{id ? titleZh : 'Create blog'}</Link>
          </BreadcrumbItem>
        </Breadcrumb>
      </div>
      <PageAlertContext.Consumer>
        {(context) => (
          <Card>
            <CardBody>
              <div className="mb-2">
                {id ? (
                  <Row>
                    <Col sm={12}>
                      {['all', 'learning'].includes(type) ? (
                        <div>
                          <Label className="mb-2">Candidate Portal Link</Label>
                          <InputGroup>
                            <Input
                              value={`https://candidate.jcprocruitc.org.hk/learning/item?id=${id}`}
                              disabled={true}
                            />
                            <InputGroupAddon addonType="append">
                              <CopyToClipboard text={`https://candidate.jcprocruitc.org.hk/learning/item?id=${id}`}>
                                <Button color="secondary">
                                  <FA name="copy" />
                                </Button>
                              </CopyToClipboard>
                            </InputGroupAddon>
                          </InputGroup>
                        </div>
                      ) : null}
                      {['all', 'blog'].includes(type) && !isExternal ? (
                        <div>
                          <Label className="mb-2">JC PROcruit C Link</Label>
                          <InputGroup>
                            <Input value={`https://jcprocruitc.org.hk/en/learnitem/${id}`} disabled={true} />
                            <InputGroupAddon addonType="append">
                              <CopyToClipboard text={`https://jcprocruitc.org.hk/en/learnitem/${id}`}>
                                <Button color="secondary">
                                  <FA name="copy" />
                                </Button>
                              </CopyToClipboard>
                            </InputGroupAddon>
                          </InputGroup>
                        </div>
                      ) : null}
                    </Col>
                  </Row>
                ) : null}
                <Row>
                  <Col sm={12} md={6}>
                    <FormGroup>
                      <Label>Chinese Title</Label>
                      <Input placeholder="中文" value={titleZh} onChange={(v) => setTitleZh(v.target.value)} />
                    </FormGroup>
                  </Col>
                  <Col sm={12} md={6}>
                    <FormGroup>
                      <Label>English Title</Label>
                      <Input placeholder="English" value={titleEn} onChange={(v) => setTitleEn(v.target.value)} />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col sm={12} md={6}>
                    <FormGroup>
                      <Label>Chinese Author</Label>
                      <Input placeholder="中文" value={authorZh} onChange={(v) => setAuthorZh(v.target.value)} />
                    </FormGroup>
                  </Col>
                  <Col sm={12} md={6}>
                    <FormGroup>
                      <Label>English Author</Label>
                      <Input placeholder="English" value={authorEn} onChange={(v) => setAuthorEn(v.target.value)} />
                    </FormGroup>
                  </Col>
                </Row>
                <FormGroup>
                  <Label>Type</Label>
                  <Input type="select" name="select" value={type} onChange={(v) => setType(v.target.value)}>
                    <option value={'all'}>{'All'}</option>
                    <option value={'blog'}>{'Blog'}</option>
                    <option value={'video'}>{'Video'}</option>
                    <option value={'learning'}>{'Learning'}</option>
                    <option value={'case'}>{'Case Study'}</option>
                    <option value={'trainee'}>{'Trainee'}</option>
                  </Input>
                </FormGroup>
                {type === 'case' ? (
                  <FormGroup>
                    <Label>Track</Label>
                    <Input type="select" name="select" value={track} onChange={(v) => setTrack(v.target.value)}>
                      <option value={''} disabled>
                        {'Please select track'}
                      </option>
                      <option value={'creativity'}>{'Creativity'}</option>
                      <option value={'technology'}>{'Technology'}</option>
                      <option value={'business'}>{'Business'}</option>
                      <option value={'healthcare'}>{'Healthcare'}</option>
                    </Input>
                  </FormGroup>
                ) : (
                  <></>
                )}
                <FormGroup>
                  <Label>Tags</Label>
                  <Input type="select" name="select" value={tag} onChange={(v) => setTag(v.target.value)}>
                    <option value={'all'}>{'All'}</option>
                    <option value={'newsletter'}>{'News Letter'}</option>
                    <option value={'videos'}>{'Videos'}</option>
                    <option value={'webinar'}>{'Webinar'}</option>
                    <option value={'casestudy'}>{'Case Study'}</option>
                    <option value={'usefularticle'}>{'Useful Article'}</option>
                    <option value={'press'}>{'Press'}</option>
                    <option value={'toolkit'}>{'Toolkit'}</option>
                  </Input>
                </FormGroup>
                {tag === 'casestudy' ? (
                  <FormGroup>
                    <Label>Sub-Tag</Label>
                    <Input type="select" name="select" value={subTag} onChange={(v) => setSubTag(v.target.value)}>
                      <option value={''} disabled>
                        {'Please Select'}
                      </option>
                      <option value={'casestudyph1'}>{'Phase 1 Case Study'}</option>
                      <option value={'casestudyph2'}>{'Phase 2 Case Study'}</option>
                    </Input>
                  </FormGroup>
                ) : (
                  <></>
                )}
                <FormGroup>
                  <Label>Thumbnail</Label>
                  <Input type="file" placeholder="Click here to upload" onChange={uploadThumbnail} />
                  <Row>
                    <Col sm={12} md={6}>
                      {!!thumbnail ? <img src={thumbnail} alt={'thumbnail'} /> : <div>No image uploaded</div>}
                    </Col>
                  </Row>
                </FormGroup>
                <FormGroup>
                  <Label>Thumbnail EN</Label>
                  <Input type="file" placeholder="Click here to upload" onChange={uploadThumbnailEn} />
                  <Row>
                    <Col sm={12} md={6}>
                      {!!thumbnailEn ? <img src={thumbnailEn} alt={'thumbnailEn'} /> : <div>No image uploaded</div>}
                    </Col>
                  </Row>
                </FormGroup>
                <FormGroup>
                  <Label>External Link</Label>
                  <Input
                    type="select"
                    name="select"
                    value={isExternal}
                    onChange={(v) => setIsExternal(v.target.value === 'true')}
                  >
                    <option value={true}>{'True'}</option>
                    <option value={false}>{'False'}</option>
                  </Input>
                </FormGroup>
                {isExternal ? (
                  <FormGroup>
                    <Label>External Url</Label>
                    <Input
                      placeholder="External Url"
                      value={externalUrl}
                      onChange={(v) => setExternalUrl(v.target.value)}
                    />
                  </FormGroup>
                ) : (
                  <>
                    <Row>
                      <Col sm={12} md={6}>
                        <FormGroup>
                          <Label>Chinese Description</Label>
                          <Input
                            onChange={(v) => setDescZh(v.target.value)}
                            placeholder="中文"
                            value={descZh}
                            type="textarea"
                          />
                        </FormGroup>
                      </Col>
                      <Col sm={12} md={6}>
                        <FormGroup>
                          <Label>English Description</Label>
                          <Input
                            onChange={(v) => setDescEn(v.target.value)}
                            placeholder="English"
                            value={descEn}
                            type="textarea"
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={12} md={6}>
                        <FormGroup>
                          <Label>Chinese content</Label>
                          <MyEditor onChange={(v) => setContentZh(v)} placeholder="中文" value={contentZh} />
                        </FormGroup>
                      </Col>
                      <Col sm={12} md={6}>
                        <FormGroup>
                          <Label>English content</Label>
                          <MyEditor onChange={(v) => setContentEn(v)} placeholder="English" value={contentEn} />
                        </FormGroup>
                      </Col>
                    </Row>
                  </>
                )}
                <Row>
                  <Col sm={12} md={6}>
                    <FormGroup>
                      <Label>Hidden</Label>
                      <Input
                        type="select"
                        name="select"
                        value={isHided}
                        onChange={(v) => setIsHided(v.target.value === 'true')}
                      >
                        <option value={true}>{'True'}</option>
                        <option value={false}>{'False'}</option>
                      </Input>
                    </FormGroup>
                  </Col>
                  <Col sm={12} md={6}>
                    <FormGroup>
                      <Label>Sequence</Label>
                      <Input
                        type="number"
                        placeholder="Sequence"
                        value={sequence}
                        onChange={(v) => setSequence(v.target.value)}
                      />
                    </FormGroup>
                  </Col>
                </Row>
              </div>
            </CardBody>
            <CardFooter>
              <Button color="primary" onClick={() => saveBlog(context)} block>
                {id ? 'Save' : 'Create'}
              </Button>
            </CardFooter>
          </Card>
        )}
      </PageAlertContext.Consumer>
    </div>
  );
}
